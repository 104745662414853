<template>
  <div class="view-account">
    <h2>Mon compte</h2>
    <div>ID: <code>{{id}}</code> </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('account', [
      'id',
    ]),
  },
  methods: {
    ...mapActions('account', [
      'load',
    ]),
  },
  async mounted() {
    this.load();
  },
};
</script>
